import React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

export const LangSwitchBtn = (props) => {
  const [toggle, setToggle] = useState(false);
  const onClickLang = () => setToggle(!toggle);
  return (
    <SLangSwicthBtn>
      <button onClick={onClickLang} tabIndex="-1">
        {props.langTxt}
      </button>
      <SLangWrap data-toggle-state={!toggle}>
        <Link to={props.jpUrl} tabIndex="-1">
          日本語
        </Link>
        <Link to={props.enUrl} tabIndex="-1">
          English
        </Link>
      </SLangWrap>
    </SLangSwicthBtn>
  );
};

const SLangSwicthBtn = styled.div`
  grid-area: langSwitch;
  margin-right: 2rem;
  border: 1px solid ${Color.dark};
  border-radius: 4px;
  position: relative;
  button {
    cursor: pointer;
    border: none;
    background: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    text-decoration: none;
    line-height: 1;
    font-size: 0.8rem;
    font-weight: 700;
  }
  @media screen and (max-width: 599px) {
    margin-right: 1rem;
  }
`;

const SLangWrap = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 0px 0px 16px -6px #666;
  &[data-toggle-state="false"] {
    display: inline-flex;
    transform: translateX(-50%) translateY(1rem);
  }
  &[data-toggle-state="true"] {
    display: none;
  }
  a {
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    color: ${Color.dark};
    text-align: center;
    line-height: 1;
    margin-top: 1rem;
    transition: 0.2s;
    padding: 0.5rem 1rem;
    &:first-of-type {
      margin-top: 0;
    }
    @media screen and (min-width: 1025px) {
      &:hover {
        color: ${Color.primary};
      }
    }
  }
  .active {
    color: ${Color.gray};
    pointer-events: none;
  }
`;
