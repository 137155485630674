export const NavList = [
  {
    href: "/",
    label: "ホーム",
    enLabel: "HOME",
    enHref: "/en/",
    key: "001",
  },
  {
    href: "/product/",
    label: "製品案内",
    enLabel: "PRODUCT",
    enHref: "/en/product/",
    key: "002",
  },
  {
    href: "/company/",
    label: "会社案内",
    enLabel: "COMPANY",
    enHref: "/en/company/",
    key: "003",
  },
  {
    href: "/recruit/",
    label: "採用情報",
    enLabel: "RECRUIT",
    enHref: "/en/recruit/",
    key: "004",
  },
  {
    href: "/contact/",
    label: "お問い合わせ",
    enLabel: "CONTACT",
    enHref: "/en/contact/",
    key: "005",
  },
];
