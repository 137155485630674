import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { useContext } from "react";
import { NavToggleContext } from "../providers/navToggleProvider";

export const Hamburger = () => {
  const { toggle, setToggle } = useContext(NavToggleContext);
  const onClickNav = () => setToggle(!toggle);
  return (
    <SHamburger onClick={onClickNav} data-toggle-state={!toggle} tabIndex="-1">
      <div data-dot-position="left"></div>
      <div data-dot-position="center"></div>
      <div data-dot-position="right"></div>
    </SHamburger>
  );
};

const SHamburger = styled.button`
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.primary};
  border-radius: 100vh;
  border: none;
  grid-area: hamburger;
  cursor: pointer;
  div {
    content: "";
    transition: all 0.2s;
    background-color: ${Color.light};
  }
  &[data-toggle-state="true"] {
    div {
      height: 4px;
      width: 4px;
      border-radius: 100vh;
      &[data-dot-position="left"] {
        transform: translateX(-4px);
      }
      &[data-dot-position="center"] {
      }
      &[data-dot-position="right"] {
        transform: translateX(4px);
      }
    }
    @media screen and (min-width: 1025px) {
      &:hover {
        div {
          &[data-dot-position="left"] {
            transform: translateX(4px);
          }
          &[data-dot-position="center"] {
          }
          &[data-dot-position="right"] {
            transform: translateX(-4px);
          }
        }
      }
    }
  }
  &[data-toggle-state="false"] {
    flex-direction: column;
    div {
      height: 1px;
      width: 22px;
      &[data-dot-position="left"] {
        transform: translateY(0.5px) rotate(45deg);
      }
      &[data-dot-position="center"] {
        opacity: 0;
      }
      &[data-dot-position="right"] {
        transform: translateY(-0.5px) rotate(-45deg);
      }
    }
  }
`;
