import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

export const Logo = (props) => {
  return (
    <SLogo>
      <Link to={props.homeUrl} tabIndex="-1">
        LIME
      </Link>
    </SLogo>
  );
};

const SLogo = styled.div`
  grid-area: logo;
  display: inline-flex;
  align-items: center;
  a {
    text-decoration: none;
    line-height: 1;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${Color.primary};
  }
`;
