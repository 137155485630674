export const FooterNavList = [
  {
    href: "/",
    label: "プライバシーポリシー",
    enHref: "/en/",
    enLabel: "privacy policy",
    key: "001",
  },
  {
    href: "/",
    label: "お問い合わせ",
    enHref: "/en/",
    enLabel: "contacy",
    key: "002",
  },
  {
    href: "/",
    label: "サイトマップ",
    enHref: "/en/",
    enLabel: "sitemap",
    key: "003",
  },
];
